<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <BackButton />
            <h1 class="m-0 text-light">{{ t('pages.more.aboutApp.osl') }}</h1>
        </div>
        <div v-if="osl">
            <div v-if="!osl.loaded">
                <div
                    style="min-height: 20vh"
                    class="card p-3 shadow-sm mb-5 d-flex flex-row justify-content-center"
                >
                    <img
                        src="@/assets/loading-icon.gif"
                        class="mt-4"
                        style="width: 1.5rem; height: 1.5rem"
                    />
                </div>
            </div>
            <div v-else>
                <div
                    style="min-height: 20vh"
                    v-if="i18n.locale.value == 'fr'"
                    class="card p-3 shadow-sm mb-5"
                >
                    <pre v-html="osl.open_source_licenses_fr"></pre>
                </div>
                <div v-else class="card p-3 shadow-sm mb-5">
                    <pre v-html="osl.open_source_licenses_en"></pre>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
pre {
    overflow-wrap: break-word;
    white-space: pre-wrap;
}
</style>

<script setup>
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLegalTextStore } from '@/stores/legal-texts-store';
import BackButton from '@/components/BackButton';
import { storeToRefs } from 'pinia';
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');

const legalTextStore = useLegalTextStore();
legalTextStore.fetchOpenSourceLicenses();
const { osl } = storeToRefs(legalTextStore);

const i18n = useI18n();
const { t } = useI18n();
</script>
