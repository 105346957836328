import { defineStore } from 'pinia';
import axios from 'axios';

export const useLegalTextStore = defineStore('legalText', {
    state: () => ({
        tos: {},
        osl: {},
    }),
    actions: {
        async fetchOpenSourceLicenses() {
            this.$state.osl.loaded = false;
            let res = await axios.get('legal/osl');
            this.$state.osl = res.data;
            this.$state.osl.loaded = true;
        },
        async fetchTermsOfService() {
            this.$state.tos.loaded = false;
            let res = await axios.get('legal/tc');
            this.$state.tos = res.data;
            this.$state.tos.loaded = true;
        },
        clearLegalTexts() {
            this.$reset();
        },
    },
    persist: true,
});
